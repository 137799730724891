table {
    th:nth-last-child(-n+3) {
       width: 1rem;
    }

    tr {
        td:nth-last-child(-n+3) {
            padding: 0.5rem 0.25rem;
         }
    }
}