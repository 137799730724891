
.errorWrapper {
    display: flex;
    flex-direction: column;
    min-block-size: 100vh;
    align-items: center;
    justify-items: center;
    padding: 1rem;
    width: 100%;
  }
  
  .errorWrapper > * {
    margin-block: 1rem;
  }
  
  .errorWrapper > :first-child:not(h1) {
    margin-block-start: 0;
  }
  
  .errorWrapper > :last-child:not(h1) {
    margin-block-end: 0;
  }
  
  .errorWrapper > h1 {
    margin-block: auto;
  }
    