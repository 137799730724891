@import "node_modules/@blueprintjs/core/lib/scss/variables";

.with-sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: var(--s1);
    align-items: stretch;
    height: 100vh;

  }

  .placeholder {
    background-image: radial-gradient(circle at bottom right,#ffcf21 0%,#e72b78 45%);

    display: flex;
    justify-content: center;
    align-items: center;

    &__illustration {
      object-fit: contain;
    }
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
   
    &__language {
      margin: 2rem 0;
    }

    &__form {
      margin: 2rem 0;
      padding-right: 3rem;
    }
  }
  
  .with-sidebar > :last-child {
    flex-basis: 500px; 
    flex-grow: 1;
  }
  
  .with-sidebar > :first-child {

    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
    