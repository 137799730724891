@import "node_modules/@blueprintjs/core/lib/scss/variables";

.with-sidebar {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100vh;
    background-image: radial-gradient(circle at bottom right,#ffcf21 0%,#e72b78 45%);

  }

  .placeholder {

    display: flex;
    justify-content: center;
    align-items: center;

    &__illustration {
      object-fit: contain;
    }
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    @media(min-width: '1024px') {
      padding: 0 2rem 0 12rem;
    }
    
   
    &__language {
      margin: 2rem 0;
    }

    &__form {
      margin: 2rem 0;
      padding-right: 3rem;
    }
  }
  
  .with-sidebar > :first-child {
    flex-basis: 600px; 
    flex-grow: 1;
  }
  
  .with-sidebar > :last-child {

    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
    